// pages/Server.js
import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ServerProfile from '../components/ServerProfile';

const apiSrc= "https://bbrapi.goaty.ing";

function Server( { user } ) {
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}/${
            String(date.getMonth() + 1).padStart(2, '0')}/${
            String(date.getFullYear()).slice(-2)} ${
            String(date.getHours()).padStart(2, '0')}:${
            String(date.getMinutes()).padStart(2, '0')}:${
            String(date.getSeconds()).padStart(2, '0')}`;
    }
    
    const { id: paramId } = useParams();
    const [server, setServer] = useState(null);
    const [loading, setLoading] = useState(true);
    const id = paramId || (user ? user.id : null);
    
    const fetchServer = async () => {
        try {
            console.log("fetching");
            const res = await axios.get(apiSrc+'/api/servers', {
                params: { id },
                withCredentials: true,
            });
            console.log(res)
            setServer(res.data);
        } catch (error) {
            console.error('Error fetching player:', error);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if( id ) {
            console.log(id)
            fetchServer();
        } else {
            console.log('noid')
            setLoading(false);
        }
    }, [id]);
    
    if (loading) {
        return (
            <Container>
            <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
            </Spinner>
            </Container>
        );
    }
    
    if (!server) {
        return (
            <Container>
            <p>No server found ..</p>
            </Container>
        );
    }
    if (!user) {
        return (
            <Container>
            <p>You must be logged in to see that...</p>
            </Container>
        );
    } else {
        console.log(server)
        return (
            <Container>
            {server.map((s) => (
                <ServerProfile key={s.id} server={s} />
            ))}
            </Container>
        );
    }
}

export default Server;
