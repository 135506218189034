import React from 'react';
import { Container, Table } from 'react-bootstrap';
import MessageLogTable from './MessageLogTable';
import ReportLogTable from './ReportLogTable';

const ServerProfile = ({ server }) => {
  return (
    <Container>
      <h2>{server.name}</h2>
      
      <h3>Messages</h3>
      <MessageLogTable messages={server.messageLog} />
      
      <h3>Reports</h3>
      <ReportLogTable reports={server.reportLog} />
    </Container>
  );
};

export default ServerProfile;
