// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
import Overview from './pages/Overview';
import Profile from './pages/Profile';
import Servers from './pages/Servers';
import Players from './pages/Players';
import Server from './pages/Server';

const apiSrc= "https://bbrapi.goaty.ing";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(apiSrc+'/api/profile', {
          withCredentials: true,
        });
        setUser(res.data.message);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    fetchUser();
  }, []);

  return (
    <Router>
      <Header user={user} />
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route
          path="/profile/:id?"
          element={
              <Profile user={user} />
          }
        />
        <Route
          path="/server/:id?"
          element={
              <Server user={user} />
          }
        />
        <Route
          path="/servers"
          element={
              <Servers />
          }
        />
        <Route
          path="/players"
          element={
              <Players />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
