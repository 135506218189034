import React from 'react';
import { Table } from 'react-bootstrap';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};

const MessageLogTable = ({ messages }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Player</th>
          <th>Message</th>
          <th>Channel</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        {messages.map((message) => (
          <tr key={message.id}>
            <td>{message.id}</td>
            <td>{message.player.name}</td>
            <td>{message.message}</td>
            <td>{message.channel}</td>
            <td>{formatDate(message.ts)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MessageLogTable;
