// pages/Servers.js
import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const apiSrc= "https://bbrapi.goaty.ing";

function Servers() {
  const [tokens, setServers] = useState([]);
  const [newServer, setNewServer] = useState({ name: '', ip: '' });

  const fetchTokens = async () => {
    try {
      const res = await axios.get(apiSrc+'/api/token', { withCredentials: true });
      setServers(res.data);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const handleAddToken = async () => {
    try {
      await axios.post(apiSrc+'/api/token', newServer, { withCredentials: true });
      fetchTokens();
      setNewServer({ name: '', ip: '' });
    } catch (error) {
      console.error('Error adding token:', error);
    }
  };

  const handleDeleteToken = async (id) => {
    try {
      await axios.delete(apiSrc+'/api/token', {
        data: { id },
        withCredentials: true,
      });
      fetchTokens();
    } catch (error) {
      console.error('Error deleting token:', error);
    }
  };

  return (
    <Container>
      <h1>Servers</h1>
      <Form className="mb-3">
        <Row>
          <Col>
            <Form.Control
              placeholder="Name"
              value={newServer.name}
              onChange={(e) => setNewServer({ ...newServer, name: e.target.value })}
            />
          </Col>
          <Col>
            <Form.Control
              placeholder="IP"
              value={newServer.ip}
              onChange={(e) => setNewServer({ ...newServer, ip: e.target.value })}
            />
          </Col>
          <Col>
            <Button variant="primary" onClick={handleAddToken}>
              Add Token
            </Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Token</th>
            <th>IP</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((token) => (
            <tr key={token.id}>
              <td>{token.id}</td>
              <td>{token.name}</td>
              <td>{token.token}</td>
              <td>{token.ip}</td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteToken(token.id)}>
                  Delete
                </Button>
                <Button variant="info" as={Link} to={`/server/${token.id}`}>
                  See
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Servers;
