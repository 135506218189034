// pages/Profile.js
import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const apiSrc= "https://bbrapi.goaty.ing";

function Profile( { user } ) {

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60); // Using Math.floor to cut off decimals
  
    let formattedTime = '';
  
    if (hours > 0) {
      formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    }
    if (remainingSeconds > 0 || formattedTime === '') {
      formattedTime += `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    }
  
    return formattedTime.trim();
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, '0')}/${
          String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)} ${
          String(date.getHours()).padStart(2, '0')}:${
          String(date.getMinutes()).padStart(2, '0')}:${
          String(date.getSeconds()).padStart(2, '0')}`;
  }
  
  const { id: paramId } = useParams();
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const id = paramId || (user ? user.id : null);

  const fetchPlayer = async () => {
    try {
      const res = await axios.get(apiSrc+'/api/players', {
        params: { id },
        withCredentials: true,
      });
      console.log(res)
      setPlayer(res.data);
    } catch (error) {
      console.error('Error fetching player:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if( id ) {
      fetchPlayer();
    } else {
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return (
      <Container>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!player) {
    return (
      <Container>
        <p>No player found ..</p>
      </Container>
    );
  }
  if (!user) {
    return (
      <Container>
        <p>You must be logged in to see that...</p>
      </Container>
    );
  }else {
    console.log(player)


    return (
      <Container>
        <h1>Profile</h1>
        <h2>{player.name}</h2>
        <img src={player.avatarfull}/>
        <div>Role: {player.isAdmin ? 'Admin' : 'Player'}</div>
        <div>Locale : {player.loccountrycode}</div>
        <div>Real name : {player.realname}</div>

        
        <div>
          <div>Total playtime : {formatTime(player.statistics.totalPlaytime)}</div>
          <div>Connections last week : {formatTime(player.statistics.connectionsLastWeek)}</div>
          <div>Connections last month : {formatTime(player.statistics.connectionsLastMonth)}</div>
          <div>Longest playtime : {formatTime(player.statistics.longestPlaytime)}</div>
        </div>
  
        <h3>Messages</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Server</th>
              <th>Message</th>
              <th>Channel</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {player.messageLog.map((message) => (
              <tr key={message.id}>
                <td>{message.id}</td>
                <td>{message.server.name}</td>
                <td>{message.message}</td>
                <td>{message.channel}</td>
                <td>{formatDate(message.ts)}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <h3>Reports as being reported</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>server</th>
              <th>Reason</th>
              <th>Type</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {player.reportedLog.map((report) => (
              <tr key={report.id}>
                <td>{report.id}</td>
                <td>{report.server.name}</td>
                <td>{report.reason}</td>
                <td>{report.type}</td>
                <td>{formatDate(report.ts)}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <h3>Reports as reporting someone</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>server</th>
              <th>Reason</th>
              <th>Type</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {player.reporterLog.map((report) => (
              <tr key={report.id}>
                <td>{report.id}</td>
                <td>{report.server.name}</td>
                <td>{report.reason}</td>
                <td>{report.type}</td>
                <td>{formatDate(report.ts)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
  
      </Container>
    );
  }
}

export default Profile;
