import React from 'react';
import { Table } from 'react-bootstrap';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};

const ReportLogTable = ({ reports }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Player reporting</th>
          <th>Player reported</th>
          <th>Reason</th>
          <th>Type</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        {reports.map((report) => (
          <tr key={report.id}>
            <td>{report.id}</td>
            <td>{report.reporter.name}</td>
            <td>{report.reported.name}</td>
            <td>{report.reason}</td>
            <td>{report.type}</td>
            <td>{formatDate(report.ts)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ReportLogTable;
