// components/Header.js
import React from 'react';
import { Navbar, Nav, Container, Button, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
const apiSrc= "https://bbrapi.goaty.ing";

function Header({ user }) {
  const location = useLocation();
  const { pathname } = location;

  const isActive = (path) => pathname === path;

  const handleLogin = () => {
    window.location.href = apiSrc+'/auth/login';
  };

  return (
    <header className="p-3 mb-3 border-bottom">
      <Container>
        <Navbar expand="lg">
          <Navbar.Brand as={Link} to="/">
            <img
              src="/logo.png"
              width="40"
              height="32"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" active={isActive('/')}>
                Overview
              </Nav.Link>
              <Nav.Link as={Link} to="/profile" active={isActive('/profile')}>
                Profile
              </Nav.Link>
              <Nav.Link as={Link} to="/players" active={isActive('/players')}>
                Players
              </Nav.Link>
              <Nav.Link as={Link} to="/servers" active={isActive('/servers')}>
                Servers
              </Nav.Link>
            </Nav>
            {user ? (
              <Dropdown align="end">
                <Dropdown.Toggle variant="light" id="dropdown-user">
                  <img
                    src={user.avatarfull}
                    alt={user.name}
                    width="32"
                    height="32"
                    className="rounded-circle"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/profile">
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="/auth/logout">Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div className="text-end">
                <Button variant="outline-primary" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            )}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}

export default Header;
